import React from 'react';
import styled from 'styled-components';
import Layout from 'components/layout';
import Head from 'components/head';
import PageTitle from 'components/pagetitle';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  max-width: 1200px;
  padding: 400px 30px 0;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 2fr repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 100px;
  grid-row-gap: 0px;
  ${MEDIA.SMALLDESKTOP`
          padding: 100px 30px 0;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(2, 1fr);
          grid-column-gap: 0px;
          grid-row-gap: 0px;
        `};
  ${MEDIA.TABLET`
            grid-template-columns: 1fr;
            grid-template-rows: repeat(3, 1fr);
            grid-column-gap: 0px;
            grid-row-gap: 80px;
          `};
  span {
    display: block;
    margin-bottom: 60px;
    font-weight: 700;
  }
  p {
    color: #7c7c7c;
  }
  .contact-us {
    ${MEDIA.SMALLDESKTOP`
    grid-area: 1 / 1 / 2 / 3;
  `};
    p {
      color: #fff;
    }
  }
  .emails {
    ${MEDIA.TABLET`
    grid-area: 3 / 1 / 4 / 2;
  `};
  }
  a {
    display: block;
    color: #7c7c7c;
  }
`;

const Contact = () => (
  <Layout>
    <Head pageTitle="Contact" />
    <PageTitle
      pagetitle="Contact"
      subtitle="Get in touch"
      url="mailto:info@asongs.co.uk"
      icon="M11.99 0l-11.99 8.723v15.277h24v-15.277l-12.01-8.723zm.001 2.472l9.793 7.113-6.735 4.588-3.049-2.47-3.049 2.471-6.737-4.589 9.777-7.113zm-9.991 9.386l5.329 3.63-5.329 4.318v-7.948zm.474 10.142l9.526-7.723 9.526 7.723h-19.052zm19.526-2.194l-5.329-4.317 5.329-3.631v7.948z"
    />
    <Container>
      <div className="contact-us">
        <p>
          Our roster boasts writers and artists from varied backgrounds,
          locations and genres, we are always looking to sign new talent. We are
          particularly active in the sync world having secured placements in
          major TV, film, video game productions, advertising campaigns around
          the world as well as ringtones and emerging technologies.
        </p>
      </div>
      <div className="office">
        <span>Mailing Address</span>
        <p>
          Unit 2<br></br>
          210 Upper Richmond Road<br></br>
          London<br></br>
          SW15 6NP
        </p>
        <p>
          <a
            href="mailto:info@asongs.co.uk"
            target="_blank"
            rel="noopener noreferrer"
          >
            info@asongs.co.uk
          </a>
        </p>
      </div>
      <div className="emails">
        <span>Contact Us</span>
        <p>
          Tom Blackburn<br></br>
          <a
            href="mailto:tom@asongs.co.uk"
            target="_blank"
            rel="noopener noreferrer"
          >
            tom@asongs.co.uk
          </a>
        </p>
        <p>
          Matt Graux<br></br>
          <a
            href="mailto:info@asongs.co.uk"
            target="_blank"
            rel="noopener noreferrer"
          >
            matt@asongs.co.uk
          </a>
        </p>
        <p>
          Thor Sutherland<br></br>
          <a
            href="mailto:thor@asongs.co.uk"
            target="_blank"
            rel="noopener noreferrer"
          >
            thor@asongs.co.uk
          </a>
        </p>
      </div>
    </Container>
  </Layout>
);

export default Contact;
